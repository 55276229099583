<template>
    <div style="display: flex; align-items: center; justify-content: center">
        <img
            src="@/assets/img/coin.png"
            width="15"
            alt=""
            v-if="type === 'fire'"
        />
        <span style="color: #b87100; font-weight: bold; font-size: 14px">
            <span v-if="type === 'coin'">￥</span>
            {{ money }}
        </span>
    </div>
</template>
<script>
export default {
    props: {
        money: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "fire", // fire, coin
        },
    },
};
</script>
